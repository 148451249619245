import React from "react";
// import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import logo from "./assets/img/logo.svg"; // Replace with the actual path to your SVG file

const Header = () => {
  return (
    <Navbar fixed="top" bg="light" data-bs-theme="light" expand="lg" id="navbar-example">
      <Container>
        <Navbar.Brand href="#home">
          <Image src={logo} className="logo" width={200} />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto fs-5 ms-3">
          <Nav.Link href="#scrollspyAbout">Home</Nav.Link>
          <Nav.Link href="#scrollspyProducts">Products</Nav.Link>
          <Nav.Link href="#scrollspyCustomSoftware">Custom-software</Nav.Link>
          <Nav.Link href="#scrollspyHardware">Hardware</Nav.Link>
          <Nav.Link href="#scrollspyContact">Contact Us</Nav.Link>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  );
};
export default Header;
