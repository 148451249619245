import React from "react";
import Header from "./header";
import Footer from "./Footer";
import { Row, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const Home = () => {
  return (
    <>
      {/* ----Header---- */}
      <Header />
      {/* ----End Header---- */}

      {/* ----Body---- */}
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-root-margin="0px 0px -40%"
        data-bs-smooth-scroll="true"
        class="scrollspy-example bg-body-tertiary rounded-2 "
        tabindex="0"
      >
        {/* --About-- */}
        <section id="scrollspyAbout" class="pt-md-11 pt-5 ">
          <div class="container p-5">
            <h1
              class="fs-1 mb-3 mt-3  text-center"
              style={{ color: "#58595B" }}
            >
              Welcome to Fronix Studio!
            </h1>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Fronix Studio</strong> is a software development company
              specializing in access control systems founded in Cairo, Egypt, by
              a professional team with more than 10 years of expertise in access
              control solutions.
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Fronix Studio</strong> provides access control solutions
              for various industries. We offer off-the-shelf and custom software
              solutions tailored to our clients’ access control needs.
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              Our technical support department is dedicated to launching
              software for customers, providing training to help them
              effectively manage the devices' software, and offering after-sale
              services to ensure we meet customer needs and deliver efficient
              service.
            </p>
            <h1
              class="fs-2 mb-3 mt-3  text-center"
              style={{ color: "#58595B" }}
            >
              Who we can serve!!
            </h1>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Fronix Studio</strong> provides access control solutions
              for various industries. We offer off-the-shelf and custom software
              solutions tailored to our clients’ access control needs.
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Events</strong> - <strong>Reservations</strong> -{" "}
              <strong>Clubs</strong> - <strong>Workplaces</strong> -{" "}
              <strong>GYM</strong> - <strong>Classes & Workshops</strong> -{" "}
              <strong>Invitations</strong> - <strong>Digital Stamps</strong> -{" "}
              <strong>Residential</strong>
            </p>
          </div>
        </section>
        {/* --End About-- */}

        {/* --Products-- */}
        <section
          id="scrollspyProducts"
          class="py-4 pt-md-11 text-light"
          style={{ backgroundColor: "#58595B" }}
        >
          <div class="container">
            <h1 class="fw-700 mb-5 text-center fs-1">Products</h1>
            <Row className="pb-5">
              <Col md={6} className="mb-5">
                <h2>KNTO Pass</h2>
                <p class="lead mb-6 mb-lg-8 fs-4">
                  Revolutionize Your Identity Management!
                </p>
                <p class="lead mb-6 mb-lg-8 fs-4">
                  Empower your organization with a cutting-edge identity and
                  access management platform from{" "}
                  <a
                    href="https://kntopass.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    KNTO Pass
                  </a>
                  . Elevate customer trust, foster stronger partner
                  relationships, and seamlessly navigate the evolving landscape
                  of digital interactions.
                </p>
                <a
                  href="https://kntopass.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Read More
                </a>
              </Col>
              <Col md={6}>
                <img
                  src={require("./assets/img/kntopass.png")}
                  width="100%"
                  alt="Knto Pass"
                />
              </Col>
            </Row>

            <Row className="mb-5 pt-5">
              <Col md={6} className="mb-5">
                <img
                  src={require("./assets/img/reservations.png")}
                  width="100%"
                  alt="Bus Reservation System"
                />
              </Col>
              <Col md={6}>
                <h2>Bus Reservation System</h2>
                <p class="lead mb-6 mb-lg-8 fs-4">
                  Choosing the reservation system that’s right for your needs!
                </p>
                <p class="lead mb-6 mb-lg-8 fs-4">
                  The Bus Reservation System aims to streamline online bookings
                  through a user-friendly mobile app, overseen by a
                  sophisticated dashboard. This dashboard allows you to manage
                  your project efficiently and generate insightful analytic
                  reports for your utilities.
                </p>
                <p class="lead mb-6 mb-lg-8 fs-4">
                  <strong>Mobile App</strong> An easy user-friendly reservation
                  mobile app.
                </p>
                <p class="lead mb-6 mb-lg-8 fs-4">
                  <strong>Mobile Reader</strong> Scan & validate the
                  reservations.
                </p>
                <p class="lead mb-6 mb-lg-8 fs-4">
                  <strong>Cloud Dashboard</strong> Where you can manage your
                  project efficiently and generate insightful analytic reports
                  for your utilities.{" "}
                </p>
                <h6>Our Loyal Clients</h6>
                <img
                  src={require("./assets/img/orange-logo.png")}
                  width="70"
                  alt="Orange Bus Reservation System"
                />
              </Col>
            </Row>
          </div>
        </section>
        {/* --End Products-- */}

        {/* --Custom Software-- */}
        <section id="scrollspyCustomSoftware" class="pt-4 pt-md-11">
          <div class="container p-5">
            <h1 class="fw-700 mb-5 text-center fs-1">Custom-software</h1>
            <h5>
              Crafting <strong>excellence</strong> in software development.
              Start your journey with our <strong>experts</strong>.
            </h5>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Fronix Studio </strong>can develop custom access control
              software, mobile apps, and web applications (backend & frontend),
              tailored to the specific needs of a user group or organization,
              addressing business-related challenges effectively.
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              To achieve results, you need a partner who can deliver value at
              any stage of your journey. <strong> Fronix Studio </strong>brings
              deep expertise in technology, business, and project management to
              create comprehensive solutions that drive both project and
              organizational success. Whether you need specific subject matter
              expertise or end-to-end project planning, solution design, and
              implementation,
              <strong> Fronix Studio </strong>is ready to deliver on time and
              within budget.
            </p>
            <h4>Software Process</h4>
            <p class="lead mb-6 mb-lg-8 fs-4">
              For our backend technology, we use the C# programming language
              with .NET Core. On the frontend, we utilize React-js for web
              development. Our database solutions include PostgreSQL, Microsoft
              Azure, and Amazon AWS. For mobile development, we employ React
              Native for cross-platform applications or native development for
              Android and iOS.
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              We also offer the following optional services for your integrated
              system:
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Ongoing maintenance and support</strong> – including an
              agreed service level agreement (SLA) and access to our in-house
              developers via our helpdesk.
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Hosting:</strong> We work with Microsoft Azure or Amazon
              AWS.
            </p>
          </div>
        </section>
        {/* --End Custom Software-- */}

        {/* --Hardware-- */}
        <section
          id="scrollspyHardware"
          class="py-4 pt-md-11 text-light"
          style={{ backgroundColor: "#58595B" }}
        >
          <div class="container">
            <h1 class="fw-700 mb-5 text-center fs-1">Hardware</h1>
            <p class="lead mb-6 mb-lg-8 fs-4">
              <strong>Fronix Studio </strong>is pleased to announce that we now
              offer hardware solutions compatible with our access control
              systems. Additionally, we can integrate with existing hardware to
              provide seamless functionality.
            </p>
          </div>
        </section>
        {/* --End Hardware-- */}

        {/* --Contact-- */}
        <section id="scrollspyContact" class="pt-4 pt-md-11">
          <div class="container p-5 text-center">
            <h1 class="fw-700 mb-5  fs-1">Contact Us</h1>
            <div class="fw-700 mb-5  fs-4">
              <div className="mb-3">
                <strong>Customer Support : </strong> +20 011 116 729 26
              </div>
              <div>
                <strong>E-mail : </strong>{" "}
                <a href="mailto:info@fronixstudio.com">info@fronixstudio.com</a>
              </div>
            </div>
            <div className="text-center mb-5">
              <a
                href="https://linkedin.com/company/fronixstudio"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-4"
              >
                {" "}
                <Icon.Linkedin size={40} />
              </a>
              <a
                href="https://www.facebook.com/fronixstudio.pass"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-4"
              >
                {" "}
                <Icon.Facebook size={40} />
              </a>
              <a
                href="https://www.instagram.com/fronix.studio"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-4"
              >
                {" "}
                <Icon.Instagram size={40} />
              </a>
            </div>
          </div>
        </section>
        {/* --End Contact-- */}
      </div>

      {/* ----End Body---- */}

      {/* ----Footer---- */}
      <Footer />
      {/* ----End Footer---- */}
    </>
  );
};
export default Home;
