import React from "react";
 
const Footer = () => {
    return (
        <footer className="footer py-3 border-top">
        <div className="d-flex flex-wrap justify-content-between align-items-center px-5"   >
            <p class="col-md-3 mb-0 text-body-secondary "> © {new Date().getFullYear()} <strong> Fronix Studio </strong></p>
        </div>
    </footer>
    );
  };
  export default Footer;
