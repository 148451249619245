
import { useRoutes } from 'react-router-dom';
 import   Home  from "./components/Home";

 const AppRoutes = () => {
    const routes = [
      { path: '/', element: <Home /> },
    // { path: 'about', element: <About /> }
];
    return useRoutes(routes);
  };
 
export default AppRoutes;